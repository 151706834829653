<!-- 成功案例 -->
<template>
  <div class="page">
    <div class="imgbox">
      <img src="../assets/about/banner11.jpg" alt="成功案例轮播图片" />
    </div>
    <div id="case_list" :class="show ? 'Fixed1' : 'Fixed'"></div>
    <div class="content">
      <div class="case_tabs" :class="show ? 'header' : 'issFixed'">
        <div
          class="case_item"
          :class="Cindex == index ? 'active' : ''"
          :style="{
            'background-image':
              Cindex == index ? 'url(' + item.bgIMG + ')' : '',
          }"
          v-for="(item, index) in caseTabs"
          :key="index"
          @click="caseClick(index)"
        >
          <div :class="Cindex == index ? 'active_bg' : 'bg'">
            <img
              :src="Cindex == index ? item.img : item.imgURL"
              alt="切换按钮背景图片"
            />
            <span>{{ item.name }}</span>
          </div>
        </div>
      </div>

      <div class="case_list" ref="header">
        <div class="item" v-for="(item, index) in caseList" :key="index">
          <div class="case_img">
            <img :src="item.caseImg" alt="平台介绍图片" />
          </div>
          <div class="case_infor">
            <div class="title">
              <span></span>
              <h2 style="margin-left: -10px">{{ item.caseName }}</h2>
            </div>
            <div class="text">
              <p v-html="item.caseCont"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "成功案例-东营市旭瑞智能科技股份有限公司", // 设置title
    meta: [
      {
        name: "keywords", // 设置关键字
        content:
          "智慧水利，PLC控制柜，闸泵远程控制，智慧城市，智慧城管，智慧水务，城市防汛监测，智慧闸泵，智慧环卫，智慧公厕，智慧园林，智慧执法，智慧渣土，市容秩序提升，智慧工地，智慧道桥，智慧井盖，智慧管网，智慧照明，智慧农业，智能灌溉，水肥一体化，物联网",
      },
      {
        name: "description", // 设置页面描述
        content:
          "旭瑞智能专注于智慧水利、智慧城管、智慧农业及智能化工程等智慧物联网细分领域软硬件产品研发、生产、销售、运营，提供智慧行业整体解决方案。",
      },
    ],
  },
  data() {
    return {
      caseTabs: [
        {
          name: "智慧水利",
          imgURL: require("../assets/product/Water_affairs.jpg"),
          img: require("../assets/product/Water_affairs_HL.jpg"),
          bgIMG: require("../assets/case/case-tab-bg-01.jpg"),
        },
        {
          name: "智慧城管",
          imgURL: require("../assets/product/Urban_management.jpg"),
          img: require("../assets/product/Urban_management_HL.jpg"),
          bgIMG: require("../assets/case/case-tab-bg-02.jpg"),
        },
        {
          name: "智慧农业",
          imgURL: require("../assets/product/Agriculture.jpg"),
          img: require("../assets/product/Agriculture_HL.jpg"),
          bgIMG: require("../assets/case/case-tab-bg-03.jpg"),
        },
      ],
      Cindex: 0,
      Pindex: 0,
      caseList: [
        {
          caseImg: require("../assets/case/yxsl.png"),
          caseName: "阳信水利综合管理平台",
          caseCont:
            "<p>阳信水利项目是基于我司自主研发的智能控制器及软件平台，融合远程智能控制、远程数据采集终端、智能传感等技术，对闸泵、雨情、水文信息、水库安全等进行数字化、网格化、智能化管理，使水利设施管理更加便捷、高效、人性化。</p>",
        },
        {
          caseImg: require("../assets/case/lkzb.png"),
          caseName: "兰考县水利综合管理平台",
          caseCont:
            "<p>河南水利项目通过对水利对象及水利管理活动进行透彻感知、网络互联、信息共享和智能分析，最终形成“更透彻的感知、更全面的互联互通、更科学的决策、更高效智能的管理”的智慧水利管理体系，为水利业务提供智能处理、决策支持和泛在服务。</p>",
        },
        {
          caseImg: require("../assets/case/jxsl.png"),
          caseName: "江西无人值守远程智能控制系统",
          caseCont:
            "<p>江西无人值守远程智能控制项目是采用物联网+混合云+大数据分析+专业模型算法等先进技术，致力服务于江河湖泊水系信息、水利工程设施、水利管理活动等实时在线监测管理领域。</p>",
        },
      ],
      show: true,
    };
  },
  created() {
    this.Pindex = this.$route.query.id - 1;
    if (this.Pindex) {
      this.Cindex = this.Pindex;
    }
    let arr = [];
    for (let i = 0; i < 2; i++) {
      arr[i] = function () {
        console.log(i);
      };
    }
    arr[0]();
    arr[1]();
  },
  mounted() {
    window.addEventListener("scroll", this.handleScrollx, true);

    if (this.Pindex == 1) {
      // this.$router.go(0)
      // if (location.href.indexOf("#reloaded") == -1) {
      //   location.href = location.href + "#reloaded";
      //   location.reload();
      // }
      this.caseList = [
        {
          caseImg: require("../assets/case/ychw.png"),
          caseName: "东营区智慧环卫综合管理平台",
          caseCont:
            "<p>智慧环卫综合管理平台是结合GIS地图、GPS定位技术、物联网等技术组建成的一体化信息管理系统。一是通过智能化改造，实现垃圾从收集、清运、焚烧的全流程监管，对环卫管理所涉及的人、车、物、事进行全过程实时管理；二是实现了对人员的出勤上岗、离岗、脱岗、旷工、长时间逗留等情况的实时监测监管；三是对环卫车辆实时状态、作业状态、作业轨迹、作业里程、油耗情况、违规情况等信息进行综合监控，全面提升了环卫作业质量，降低了环卫运营成本；四是把握环卫工作在作业、运行、管理、预期等各个方面所隐含的问题，通过图文上传和数据研读，实现更合理的环卫动态资源配置，促进环卫行业向智能化、高效化方向发展。</p>",
        },
        {
          caseImg: require("../assets/case/zhgc.png"),
          caseName: "东营区智慧公厕管理平台",
          caseCont:
            "<p>含异味气体探测报警、智慧公厕引导、视频监控、公厕导航、养护企业监管等系统，通过物联网技术手段，实现公厕的智能化监管。</p>",
        },
        {
          caseImg: require("../assets/case/zhyl.png"),
          caseName: "智慧园林系统",
          caseCont:
            "<p>具有GIS地图、人员考勤、范围划分、智能手表、虫情监测、监控资源、土壤监测、泵站管理、用水/用电分析、认证单管理、病虫害管理、信息统计、管养计划与问题整改等功能；实现园林智能水肥监管、养护作业实时动态管理、苗木全生命周期管理三大功能。</p>",
        },
        {
          caseImg: require("../assets/case/zhzt.png"),
          caseName: "东营区建筑垃圾综合信息管理平台",
          caseCont:
            "<p>包含三个子系统：建筑垃圾全过程管理、建筑垃圾属地管理、监督监察管理。建筑垃圾全过程管理对装修垃圾产生的源头、运输、处置进行全过程监管并进行数字 图像表格分析。建筑垃圾属地管理设有镇街子系统入口 方便各个镇街对自己辖区的建筑垃圾产生、运输、处置等内容进行管理。监督监察管理则为执法局、审批局、住建局、交通局、交警等职能部门提供分管子系统， 整合各方力量协作监管建筑垃圾管理工作。</p>",
        },
        {
          caseImg: require("../assets/case/srsm.png"),
          caseName: "东营区市容市貌秩序提升平台",
          caseCont:
            "<p>可针对市容环境和市容秩序等类型的违章事件进行智能分析告警，并能够根据素材进行不断的机器学习和完善优化。实现对城市范围内市政、市容的联网监控，为城市部门快速发现违章事件、实时跟踪事件处理进展提供有力支持。大大降低人力成本，高效监管城市流动摊位，建立起地摊经济有序、安全的新形象。</p>",
        },
        {
          caseImg: require("../assets/case/lzz.png"),
          caseName: "东营区路长制综合管理平台",
          caseCont:
            "<p>路长制综合管理平台依托物联网技术建立“统筹协调、集中高效”的城市综合管理运行机制，由巡查人员对城市环境卫生、市容市貌、乱摆乱放等9大城市治理问题通过平台和手机APP进行“图文上报、协调处理、进度查询、监督落实”，大幅提高各级管理人员工作效率。",
        },
      ];
    }
    if (this.Pindex == 2) {
      // this.$router.go(0)
      // if (location.href.indexOf("#reloaded") == -1) {
      //   location.href = location.href + "#reloaded";
      //   location.reload();
      // }
      this.caseList = [
        {
          caseImg: require("../assets/case/zhgg.png"),
          caseName: "智能化灌溉系统管理平台",
          caseCont:
            "<p>智能灌溉是一套集中多个功能的综合系统，里面含有水文气象信息采集系统、远程视频监控报警系统、太阳能供电系统、远程自动化控制及传输系统以及综合调度控制中心等。能实现整个农场或牧场的远程监控和自动语音报警、采集和上传现场设备的数据、远程控制整个农场或牧场的灌溉设备，达到足不出户就能远程遥控灌溉、节约人力、物力的目的。</p>",
        },
        {
          caseImg: require("../assets/case/zhnc.png"),
          caseName: "旭瑞智慧农场试点项目",
          caseCont:
            "<p>旭瑞智慧农场通过现场部署多种传感器、水泵、智能喷灌机、电动闸阀、视频监控、太阳能供电等设备，结合智能喷灌技术、水肥水药一体化技术，实现农场的高度智能化管理、作物长势及土壤墒情自动监测、大数据分析等功能。让用户随时随地通过电脑或手机APP就可以远程喷灌农作物，并对农场生产环境进行精确监测和控制，降低种植成本，提高作物产量，为农产的生产和管理提供数据分析和决策支撑。</p>",
        },
      ];
    }
  },
  updated() {
    if (this.$route.query.name) {
      this.$el.querySelector(this.$route.query.name).scrollIntoView();
    }
  },
  methods: {
    handleScrollx() {
      // console.log(
      //   "距离顶部高度",
      //   this.$refs.header.getBoundingClientRect().top
      // );
      if (this.$refs.header.getBoundingClientRect().top < 200) {
        this.show = false;
      }
      if (this.$refs.header.getBoundingClientRect().top > 200) {
        this.show = true;
      }
    },
    getCaseData() {
      let data = {
        infoTypeId: 6,
      };
      this.$API.getArticleList(data).then((res) => {
        console.log("res :成功案例>> ", res);
      });
    },
    caseClick(index) {
      this.Cindex = index;
      console.log(index);

      if (index == 0) {
        this.caseList = [
          {
            caseImg: require("../assets/case/yxsl.png"),
            caseName: "阳信水利综合管理平台",
            caseCont:
              "<p>阳信水利项目是基于我司自主研发的智能控制器及软件平台，融合远程智能控制、远程数据采集终端、智能传感等技术，对闸泵、雨情、水文信息、水库安全等进行数字化、网格化、智能化管理，使水利设施管理更加便捷、高效、人性化。</p>",
          },
          {
            caseImg: require("../assets/case/lkzb.png"),
            caseName: "兰考县水利综合管理平台",
            caseCont:
              "<p>河南水利项目通过对水利对象及水利管理活动进行透彻感知、网络互联、信息共享和智能分析，最终形成“更透彻的感知、更全面的互联互通、更科学的决策、更高效智能的管理”的智慧水利管理体系，为水利业务提供智能处理、决策支持和泛在服务。</p>",
          },
          {
            caseImg: require("../assets/case/jxsl.png"),
            caseName: "江西无人值守远程智能控制系统",
            caseCont:
              "<p>江西无人值守远程智能控制项目是采用物联网+混合云+大数据分析+专业模型算法等先进技术，致力服务于江河湖泊水系信息、水利工程设施、水利管理活动等实时在线监测管理领域。</p>",
          },
        ];
      } else if (index == 1) {
        this.caseList = [
          {
            caseImg: require("../assets/case/ychw.png"),
            caseName: "东营区智慧环卫综合管理平台",
            caseCont:
              "<p>智慧环卫综合管理平台是结合GIS地图、GPS定位技术、物联网等技术组建成的一体化信息管理系统。一是通过智能化改造，实现垃圾从收集、清运、焚烧的全流程监管，对环卫管理所涉及的人、车、物、事进行全过程实时管理；二是实现了对人员的出勤上岗、离岗、脱岗、旷工、长时间逗留等情况的实时监测监管；三是对环卫车辆实时状态、作业状态、作业轨迹、作业里程、油耗情况、违规情况等信息进行综合监控，全面提升了环卫作业质量，降低了环卫运营成本；四是把握环卫工作在作业、运行、管理、预期等各个方面所隐含的问题，通过图文上传和数据研读，实现更合理的环卫动态资源配置，促进环卫行业向智能化、高效化方向发展。</p>",
          },
          {
            caseImg: require("../assets/case/zhgc.png"),
            caseName: "东营区智慧公厕管理平台",
            caseCont:
              "<p>含异味气体探测报警、智慧公厕引导、视频监控、公厕导航、养护企业监管等系统，通过物联网技术手段，实现公厕的智能化监管。</p>",
          },
          {
            caseImg: require("../assets/case/zhyl.png"),
            caseName: "智慧园林系统",
            caseCont:
              "<p>具有GIS地图、人员考勤、范围划分、智能手表、虫情监测、监控资源、土壤监测、泵站管理、用水/用电分析、认证单管理、病虫害管理、信息统计、管养计划与问题整改等功能；实现园林智能水肥监管、养护作业实时动态管理、苗木全生命周期管理三大功能。</p>",
          },
          {
            caseImg: require("../assets/case/zhzt.png"),
            caseName: "东营区建筑垃圾综合信息管理平台",
            caseCont:
              "<p>包含三个子系统：建筑垃圾全过程管理、建筑垃圾属地管理、监督监察管理。建筑垃圾全过程管理对装修垃圾产生的源头、运输、处置进行全过程监管并进行数字 图像表格分析。建筑垃圾属地管理设有镇街子系统入口 方便各个镇街对自己辖区的建筑垃圾产生、运输、处置等内容进行管理。监督监察管理则为执法局、审批局、住建局、交通局、交警等职能部门提供分管子系统， 整合各方力量协作监管建筑垃圾管理工作。</p>",
          },
          {
            caseImg: require("../assets/case/1.png"),
            caseName: "东营区市容市貌秩序提升平台",
            caseCont:
              "<p>可针对市容环境和市容秩序等类型的违章事件进行智能分析告警，并能够根据素材进行不断的机器学习和完善优化。实现对城市范围内市政、市容的联网监控，为城市部门快速发现违章事件、实时跟踪事件处理进展提供有力支持。大大降低人力成本，高效监管城市流动摊位，建立起地摊经济有序、安全的新形象。</p>",
          },
          {
            caseImg: require("../assets/case/lzz.png"),
            caseName: "东营区路长制综合管理平台",
            caseCont:
              "<p>路长制综合管理平台依托物联网技术建立“统筹协调、集中高效”的城市综合管理运行机制，由巡查人员对城市环境卫生、市容市貌、乱摆乱放等9大城市治理问题通过平台和手机APP进行“图文上报、协调处理、进度查询、监督落实”，大幅提高各级管理人员工作效率。</p>",
          },
        ];
      } else if (index == 2) {
        this.caseList = [
          {
            caseImg: require("../assets/case/zhgg.png"),
            caseName: "智能化灌溉系统管理平台",
            caseCont:
              "<p>智能灌溉是一套集中多个功能的综合系统，里面含有水文气象信息采集系统、远程视频监控报警系统、太阳能供电系统、远程自动化控制及传输系统以及综合调度控制中心等。能实现整个农场或牧场的远程监控和自动语音报警、采集和上传现场设备的数据、远程控制整个农场或牧场的灌溉设备，达到足不出户就能远程遥控灌溉、节约人力、物力的目的。</p>",
          },
          {
            caseImg: require("../assets/case/zhnc.png"),
            caseName: "旭瑞智慧农场试点项目",
            caseCont:
              "<p>旭瑞智慧农场通过现场部署多种传感器、水泵、智能喷灌机、电动闸阀、视频监控、太阳能供电等设备，结合智能喷灌技术、水肥水药一体化技术，实现农场的高度智能化管理、作物长势及土壤墒情自动监测、大数据分析等功能。让用户随时随地通过电脑或手机APP就可以远程喷灌农作物，并对农场生产环境进行精确监测和控制，降低种植成本，提高作物产量，为农产的生产和管理提供数据分析和决策支撑。</p>",
          },
        ];
      }
      this.$router.push({
        path: "/case",
        query: {
          name: "#case_list",
        },
      });
      // this.$router.go(0)
     
    },
  },
};
</script>

<style lang="less">
h2 {
  font-size: 24px;
}
.page {
  .isFixed {
    height: 100px;
  }
  .imgbox {
    width: 100%;
    height: 600px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .Fixed {
    height: 110px;
  }
  .Fixed1 {
    height: 20px;
  }
  .content {
    width: 60%;
    margin: 50px auto;
    .header {
      display: flex;
      justify-content: center;
      margin-bottom: 100px;
      .case_item {
        cursor: pointer;
        width: 33%;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: rgba(226, 225, 225, 0.5);
        overflow: hidden;
        img {
          width: 40px;
          height: 30px;
          margin-right: 10px;
        }
        span {
          font-size: 26px;
          color: #004aa1;
        }
      }
      .bg,
      .active_bg {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active_bg {
        background-color: rgba(0, 0, 0, 0.5);
      }
      .case_item:nth-child(1) {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        border-right: 1px solid #fff;
      }
      .case_item:nth-last-child(1) {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
        border-left: 1px solid #fff;
      }
      .active {
        background-repeat: no-repeat;
        background-size: 100% 100%;
        span {
          color: #fff;
        }
      }
    }
    .issFixed {
      position: fixed;
      top: 82px;
      left: 0px;
      right: 0;
      display: flex;
      width: 30%;
      margin: auto;
      justify-content: center;
      .case_item {
        width: 100px;
        height: 20px;
        padding: 20px;
        z-index: 1;
        margin-right: 30px;
        background-color: rgba(226, 225, 225, 0.9);
        img {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .bg,
      .active_bg {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .active_bg {
        background-color: rgba(0, 0, 0, 0);
      }
      .case_item:nth-child(1) {
        border-radius: 50px;
        // border-right: 1px solid #fff;
        span {
          color: #004aa1;
        }
      }
      .case_item:nth-child(2) {
        border-radius: 50px;
        // border-right: 1px solid #fff;
        span {
          color: #004aa1;
        }
      }
      .case_item:nth-last-child(1) {
        border-radius: 50px;
        // border-left: 1px solid #fff;
        span {
          color: #004aa1;
        }
      }
      .active {
        // background-repeat: no-repeat;
        background-size: 1000% 1000%;
        span {
          color: #fff;
        }
      }
    }
    .case_list {
      .item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        background-color: rgba(226, 225, 225, 0.5);
        border-radius: 10px;
        .case_img {
          width: 60%;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
        .case_infor {
          width: 33%;
          padding: 2%;
          .title {
            display: flex;
            align-items: center;
            span:nth-child(1) {
              display: block;
              width: 8px;
              height: 26px;
              background-color: #004aa1;
              margin-right: 20px;
            }
            span:nth-child(2) {
              font-size: 24px;
              font-weight: 600;
            }
          }
          .text {
            margin-top: 50px;
            line-height: 30px;
          }
        }
      }
      .item:nth-child(2n) {
        flex-direction: row-reverse;
      }
      .item:nth-child(2n + 1) {
        flex-direction: row;
      }
    }
  }
}
</style>